<template>
  <div class="page_bg">
    <!-- 顶部 card-->
    <div class="bg_top_linear-gradient">
      <!-- 顶部返回 -->
      <div class="back_top_name flex-r-s-c">
        <van-icon name="arrow-left" @click="backPath" />
        <div class="top_name flex-c-s-s" style="margin-left:0.5rem">
          <div class="order_del_status">
            <van-count-down :time="orderInfo.time" format="mm:ss"
                            style="color:#fff;font-weight:bold;font-size:0.48rem"
            />
          </div>
          <div class="order_del_tip" style="font-size: 0.32rem;">
            <span style="color:rgba(255,255,255,0.6)">长时间不付款，订单将会取消哦~</span>
          </div>
        </div>
        <div class="top_icon" />
      </div>
    </div>
    <!-- 商品区域 -->
    <div class="study_pro_box flex-c-c-c" style="margin-top: -3.5rem;">
      <div class="company_pro_list flex-c-c-c">
        <classCard02 :info="classInfo" />
      </div>
    </div>
    <!-- 订单信息 -->
    <div class="study_order_box">
      <van-cell-group>
        <van-cell title="支付金额" :value="'￥'+totalMoney" />
        <van-cell v-if="choosedCoupon" title="优惠券" is-link :value="'优惠'+couponInfo.money+'元'" @click="couronChange" />
        <van-cell v-else title="优惠券" is-link :value="couponList.length === 0?'无可用优惠': couponList.length+'张可用'" @click="couronChange" />
        <van-cell title="购买账号" :value="orderInfo.phone" />
        <van-cell title="课程时效" :value="orderInfo.effectiveTime" />
        <van-cell title="使用方式" value="线上观看" />
      </van-cell-group>
    </div>
    <!-- 底部bar -->
    <orderStudySub ref="orderSub" :retotal="totalMoney" @subOrder="subOrder" />
    <!-- 优惠券列表 -->
    <van-popup v-model="showList" round position="bottom" style="height: 60%; padding-top: 4px;">
      <van-image v-for="(item1,index1) in couponList" :key="index1" width="8.93rem" height="2.29rem"
                 :src="item1.icon" @click="chooseCoupon(item1)"
      />
    </van-popup>
  </div>
</template>

<script>
import './index.scss'
import './../cart/index.scss'
import orderStudySub from '@/components/bar/order_study_sub'
import { cloudroom_queryOrderById, cloudroom_queryConponList } from '@/api/study'
import { payStudy_class } from '@/api/order'
import classCard02 from '@/components/class_card_02'
export default {
  name: 'OrderDetail',
  components: { classCard02, orderStudySub },
  data() {
    return {
      orderId: '',
      orderInfo: {},
      classInfo: {},
      ischangeAddress: false,
      activeList: [],
      couponList: [],
      choosedCoupon: false,
      couponInfo: {},
      showList: false,
      totalMoney: 0,
      couponId: ''
    }
  },
  created() {
    this.orderId = this.$route.query.id
    this.getDetail()
  },
  methods: {
    backPath() { window.history.back() },
    getDetail() {
      // 订单信息
      cloudroom_queryOrderById({ orderId: this.orderId }).then(res => {
        this.orderInfo = res.data
        this.classInfo = res.data.cloudRoomCourse
        this.classInfo.playNum = this.$route.query.playNum
        this.totalMoney = res.data.payMoney
        console.log(res.data)
        this.loadCoupon(res.data)
        // 倒计时
        const startTime = new Date().getTime()
        const endTime = new Date(res.data.expireTime.replace(/-/g, '/')).getTime()
        this.orderInfo.time = endTime - startTime
      })
    },
    // 加载优惠券
    loadCoupon(info) {
      cloudroom_queryConponList({page: 1, limit: 999, money: info.payMoney, cloudRoomCourseId: info.cloudRoomCourse.cloudRoomCourseId}).then(res => {
        this.couponList = res.data.list
      })
    },
    // 选择优惠券
    chooseCoupon(item) {
      this.showList = false
      this.choosedCoupon = true
      this.couponInfo = item
      this.totalMoney = (this.orderInfo.payMoney * 1 - item.money * 1).toFixed(2)
      this.couponId = item.couponId
    },
    // 切换优惠券
    couronChange() {
      if (this.couponList.length !== 0) {
        this.showList = true
      }
    },
    // 提交订单
    subOrder() {
      this.$toast.loading({ message: '订单提交中...', forbidClick: true, loadingType: 'spinner', duration: 0 })
      const parms = {
        couponId: this.couponId,
        orderId: this.orderId,
        phone: this.orderInfo.phone,
        remarks: null,
        type: 2
      }
      // 调起支付
      payStudy_class(parms)
    }
  }
}
</script>

<style>
</style>
