import bossXypq from '../utils/bossXypq'

// 首页banner
export function cloudroom_banner(data) {
  return bossXypq({ url: 'hx.baoxian/cloudroom/booth/list', method: 'POST', data })
}

// 1:推荐课程列表  3:查询收藏课程列表
export function course_list(data, type) {
  return bossXypq({ url: parseInt(type) === 1 ? 'hx.baoxian/cloudroom/course/topList' : 'hx.baoxian/cloudroom/course/collectList', method: 'POST', data })
}

// 我的课程
export function cloudroom_queryOrderList(data) {
  return bossXypq({ url: 'hx.baoxian/cloudroom/app/order/queryOrderList', method: 'POST', data })
}
// 查询搜索课程列表
export function cloudroom_searchList(data) {
  return bossXypq({ url: 'hx.baoxian/cloudroom/course/searchList', method: 'POST', data })
}
// 查询推荐课程列表
export function cloudroom_topList(data) {
  return bossXypq({ url: 'hx.baoxian/cloudroom/course/topList', method: 'POST', data })
}
// 课程详情
export function cloudroom_info(data) {
  return bossXypq({ url: 'hx.baoxian/cloudroom/course/info', method: 'POST', data })
}
// 收藏课程信息
export function cloudroom_collect(data) {
  return bossXypq({ url: 'hx.baoxian/cloudroom/course/collect', method: 'POST', data })
}
// 创建课程预订单
export function cloudroom_saveOrder(data) {
  return bossXypq({ url: 'hx.baoxian/cloudroom/app/order/saveOrder', method: 'POST', data })
}
// 根据用户查询订单详情
export function cloudroom_queryOrderById(data) {
  return bossXypq({ url: 'hx.baoxian/cloudroom/app/order/queryOrderById', method: 'POST', data })
}
// 查询可使用优惠卷
export function cloudroom_queryConponList(data) {
  return bossXypq({ url: 'hx.baoxian/cloudroom/app/order/queryConponList', method: 'POST', data })
}
